/* Container styling */
.arkestra-container {
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    color: #444;
    background-color: #f8f9fa;
    margin-top: 55px;
  }
  
  /* Hero Section */
  .arkestra-header {
    background: linear-gradient(to right, #ffafbd, #ffc3a0);
    color: white;
    text-align: center;
    padding: 100px 20px;
    position: relative;
    overflow: hidden;
  }
  
  .hero-overlay {
    position: relative;
    z-index: 2;
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    font-style: italic;
    opacity: 0.9;
  }
  
  /* Section Styling */
  .arkestra-section {
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: -60px auto 0;
    max-width: 800px;
  }
  
  .content-wrapper {
    padding: 20px;
  }
  
  .section-title {
    font-size: 2rem;
    color: #ff7e5f;
    margin-bottom: 20px;
  }
  
  .section-description {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 30px;
  }
  
  /* Features Styling */
  .features-title {
    font-size: 1.6rem;
    margin-bottom: 15px;
    color: #444;
  }
  
  .features-list {
    list-style: none;
    padding: 0;
  }
  
  .features-list li {
    margin-bottom: 15px;
    padding-left: 25px;
    position: relative;
  }
  
  .features-list li::before {
    content: '✔';
    color: #ff7e5f;
    position: absolute;
    left: 0;
    top: 2px;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
  
    .hero-subtitle {
      font-size: 1.2rem;
    }
  
    .section-title {
      font-size: 1.8rem;
    }
  }
  