/* Top Header Container */
.arkestra-top-header {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.8)
      ),
      url(); /* Replace with your background image */
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
    position: relative;
  }
  
  /* Title Styling */
  .header-title {
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
    margin-bottom: 20px;
  }
  
  /* Subtitle Styling */
  .header-subtitle {
    font-size: 1.25rem;
    line-height: 1.8;
    margin-top: 15px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .header-title {
      font-size: 2.5rem;
    }
  
    .header-subtitle {
      font-size: 1rem;
    }
  }
  