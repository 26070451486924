
/* HomePage.css */

/* Ensure all category images have the same height and cover the card area */
.category-image {
    height: 300px; /* Adjust as needed */
    object-fit: cover;
  }
  
  /* Optional: Add a transition for the collapse effect */
  .collapse-enter {
    transition: height 0.3s ease;
  }
  .collapse-enter-active {
    height: auto;
  }
  .collapse-exit {
    transition: height 0.3s ease;
  }
  .collapse-exit-active {
    height: 0;
  }
  
  /* Optional: Style the subcategories section */
  #subcategory-collapse- {
    /* Additional styles if needed */
  }
  

  .bg-gray {
    background-color: #2C2F33; /* Dark gray background */
    color: white; /* Optional: Set the text color */
  }


  .fancy-font {
    font-family: 'Poppins', sans-serif; /* Use the fancy font */
    font-size: 1.2rem; /* Adjust the size */
    color: #e63946; /* Optional: Add a vibrant color */
    text-align: left; /* Center-align the name */
  }


  /* Language Wise Container */
.language-wise-container {
  font-family: 'Poppins', sans-serif;
  color: #444;
  line-height: 1.6;
}

/* Header Section */
.language-header {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  color: white;
  text-align: center;
  padding: 100px 20px;
  position: relative;
  overflow: hidden;
}

.language-hero-overlay {
  position: relative;
  z-index: 2;
}

.language-hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.language-hero-subtitle {
  font-size: 1.5rem;
  font-style: italic;
  opacity: 0.9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .language-hero-title {
    font-size: 2rem;
  }

  .language-hero-subtitle {
    font-size: 1.2rem;
  }
}

  