.all-news-container {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .news-card {
    background: #fff;
    border: 2px solid #02152a;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(99, 3, 3, 0.2);
    border-color: #0056b3;
  }
  
  .news-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .news-card h4 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 8px;
  }
  
  .news-card p {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 12px;
  }
  
  .news-card a {
    color: #021324;
    text-decoration: none;
    font-weight: bold;
  }
  
  .news-card a:hover {
    text-decoration: underline;
  }
  