body{
    color: #6F8BA4;
    margin-top:20px;
}
.section {
    padding: 100px 0;
    position: relative;
}
.gray-bg {
    background-color: #f5f5f5;
}
img {
    max-width: 100%;
}
img {
    vertical-align: middle;
    border-style: none;
}
/* About Me 
---------------------*/
.about-text h3 {
  font-size: 45px;
  font-weight: 700;
  margin: 0 0 6px;
}
@media (max-width: 767px) {
  .about-text h3 {
    font-size: 35px;
  }
}
.about-text h6 {
  font-weight: 600;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .about-text h6 {
    font-size: 18px;
  }
}
.about-text p {
  font-size: 18px;
  max-width: 450px;
}
.about-text p mark {
  font-weight: 600;
  color: #20247b;
}

.about-list {
  padding-top: 10px;
}
.about-list .media {
  padding: 5px 0;
}
.about-list label {
  color: #20247b;
  font-weight: 600;
  width: 88px;
  margin: 0;
  position: relative;
}
.about-list label:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
  width: 1px;
  height: 12px;
  background: #20247b;
  -moz-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
  margin: auto;
  opacity: 0.5;
}
.about-list p {
  margin: 0;
  font-size: 15px;
}

@media (max-width: 991px) {
  .about-avatar {
    margin-top: 30px;
  }
}

.about-section .counter {
  padding: 22px 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
}
.about-section .counter .count-data {
  margin-top: 10px;
  margin-bottom: 10px;
}
.about-section .counter .count {
  font-weight: 700;
  color: #20247b;
  margin: 0 0 5px;
}
.about-section .counter p {
  font-weight: 600;
  margin: 0;
}
mark {
    background-image: linear-gradient(rgba(252, 83, 86, 0.6), rgba(252, 83, 86, 0.6));
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: 0 bottom;
    background-color: transparent;
    padding: 0;
    color: currentColor;
}
.theme-color {
    color: #fc5356;
}
.dark-color {
    color: #20247b;
}

.social-links {
  display: flex;
  gap: 20px; /* Increased space between icons */
  justify-content: flex-start; /* Align icons to the left */
}

.social-link {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-link:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

/* Ensure all category images have the same height and cover the card area */
.category-image {
  object-fit: cover; /* Ensures the image fills the card while maintaining its aspect ratio */
  width: 100%;       /* Makes the image take the full width of the card */
  height: 100%;      /* Ensures the image takes the full height of the card */
  border-radius: 8px; /* Optional: Adds rounded corners to match the card design */
  height: 300px;
}


.youtube-icon-link {
  color: #ff0000; /* YouTube red color */
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
}

.youtube-icon-link:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.instagram-icon-link {
  color: #c13584; /* YouTube red color */
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
}

.instagram-icon-link:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}


/* Fancy Social Media Section */
.social-media-section {
  padding: 50px 0;
  background: linear-gradient(to right, #ffafbd, #ffc3a0);
  border-top: 2px solid #ff7b54;
  color: #fff;
}

.fancy-heading {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.social-card {
  border: none;
  transition: transform 0.3s, box-shadow 0.3s;
  background: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.social-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
}

.fancy-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.fancy-link:hover .social-title {
  color: #ff7b54;
}

.social-title {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  transition: color 0.3s;
}

/* Hover Effect for FontAwesome Icons */
.fancy-link:hover svg {
  color: #ff7b54;
  transform: scale(1.1);
  transition: transform 0.3s, color 0.3s;
}





