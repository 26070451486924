.moving-text {
    display: inline-block;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden;
    animation: marquee 10s linear infinite; /* Animation for 10 seconds */
    position: absolute;
    left: 0; /* Start at the left edge */
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(-100%); /* Start completely off-screen to the left */
    }
    100% {
      transform: translateX(100%); /* Move completely off-screen to the right */
    }
  }
  