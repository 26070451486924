/* Container Styling */
.about-arkestra-container {
    font-family: 'Poppins', sans-serif;
    color: #444;
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Title Styling */
  .fancy-title {
    font-size: 2.5rem;
    color: #ff7e5f;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  }
  
  /* Card Styling */
  .fancy-card {
    background: linear-gradient(to right, #ffafbd, #ffc3a0);
    border: none;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .fancy-card-title {
    font-size: 1.8rem;
    color: #ff7e5f;
    font-weight: bold;
  }
  
  .fancy-card-text {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #444;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .fancy-title {
      font-size: 2rem;
    }
  
    .fancy-card-title {
      font-size: 1.5rem;
    }
  
    .fancy-card-text {
      font-size: 1rem;
    }
  }
  