  /* Language Wise Container */
  .language-wise-container {
    font-family: 'Poppins', sans-serif;
    color: #444;
    line-height: 1.6;
  }
  
  .story-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .story-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .story-card h4 {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 8px;
  }
  
  .story-card p {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 12px;
  }

  .stories-header {
    margin-bottom: 40px;
    padding: 20px 0;
    background: linear-gradient(135deg, #f6d365, #fda085);
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .header-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .header-subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }

  /* Header Section */
.language-header {
  background: linear-gradient(to right, #ffafbd, #ffc3a0);
    color: white;
    text-align: center;
    padding: 100px 0px;
    position: relative;
    overflow: hidden;
    margin-top: 55px;
  }
  
  .language-hero-overlay {
    position: relative;
    z-index: 2;
  }
  
  .language-hero-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  
  .language-hero-subtitle {
    font-size: 1.5rem;
    font-style: italic;
    opacity: 0.9;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .language-hero-title {
      font-size: 2rem;
    }
  
    .language-hero-subtitle {
      font-size: 1.2rem;
    }
  }
  